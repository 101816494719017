import type { CustomCellRendererProps } from "@ag-grid-community/react";
import { type FunctionComponent } from "react";

export const ProductCellRenderer: FunctionComponent<
    CustomCellRendererProps
> = (params) => (
    <div className="w-full flex items-center gap-2">
        <div>
            <img
                src={params.data.imageUrl}
                alt={params.data.productName}
                className="w-[75px] min-w-[75px] h-auto"
                width={75}
            />
        </div>
        <div className="w-[calc(100%-75px)] h-auto"> {/* Allow content to shrink/grow */}
            <div className="text-base font-semibold truncate">
                {params.data.productName}
            </div>
            <div className="text-sm truncate">{params.data.category}</div>
        </div>
    </div>
);
