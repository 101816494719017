import { DR_Cause } from '@rabbit/data/portal';
import { CauseLink, TenantLinkFieldName } from '@rabbit/data/types';
import { useFirestoreQueryData } from '@react-query-firebase/firestore';
import { query, orderBy, where } from 'firebase/firestore';
import { t } from 'i18next';
import { getTenantIdFromLexicon } from '@rabbit/bizproc/client';
import {
  nestApiCreateCause,
  nestApiUpdateCause,
  nestApiDisableCause,
  nestApiEnableCause,
} from '@rabbit/bizproc/core';
import { CauseInputParamsDto } from '@rabbit/nest/api';

/**
 * Used in Sage.
 * This hook is used for CRUD operations of causes.
 */
export function useManageCauses(get_disabled = false, docid?: string) {
  /* -------------------------------------------------------------------------- */
  /*                              DB data fetching                              */
  /* -------------------------------------------------------------------------- */

  const tenantId = t(TenantLinkFieldName);

  const queryConstraints = [
    orderBy('tcreate'),
    where('tenantLink', '==', tenantId),
  ];

  if (docid) queryConstraints.push(where('docid', '==', docid));
  if (!get_disabled) queryConstraints.push(where('enabled', '==', true));

  const causeQuery = query(DR_Cause.collection(), ...queryConstraints);

  const causeList = useFirestoreQueryData(['cause-list', docid], causeQuery, {
    subscribe: true,
  });

  async function createCause(formData: CauseInputParamsDto) {
    if (docid)
      throw new Error('Cannot create a cause while editing a different one');
    if (!formData || !formData.title)
      throw new Error('Invalid form data provided');

    const { title, description } = formData;

    const tenantLink = await getTenantIdFromLexicon();

    try {
      await nestApiCreateCause({
        tenantLink,
        input: {
          title,
          description: description ?? '',
        },
      });
    } catch (err) {
      throw new Error('Error creating cause!');
    }
  }

  async function editCause(docid: CauseLink, formData: CauseInputParamsDto) {
    if (!formData || !formData.title)
      throw new Error('Invalid form data provided');

    const { title, description } = formData;

    try {
      await nestApiUpdateCause({
        docid: docid,
        input: {
          title,
          description: description ?? '',
        },
      });
    } catch (err) {
      throw new Error('Error updating cause!');
    }
  }

  async function disableCause(docid: string) {
    try {
      await nestApiDisableCause({ docid });
    } catch (err) {
      throw new Error('Error disabling cause!');
    }
  }

  async function enableCause(docid: string) {
    try {
      await nestApiEnableCause({ docid });
    } catch (err) {
      throw new Error('Error enabling cause!');
    }
  }

  return {
    isLoading: causeList.isLoading,
    causeList,
    createCause,
    editCause,
    disableCause,
    enableCause,
  };
}

