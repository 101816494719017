import {
  CauseCreateDto,
  CauseUpdateDto,
  DocIdParamDto,
} from '@rabbit/nest/api';
import { FBF_CloudCallable } from '@rabbit/firebase/adapter';

// export const nestApiGetCause = FBF_CloudCallable<any, any>(
//   'api/causes|GET'
// );

export const nestApiCreateCause = FBF_CloudCallable<CauseCreateDto, any>(
  'api/causes|POST'
);

export const nestApiUpdateCause = FBF_CloudCallable<
  DocIdParamDto & CauseUpdateDto,
  any
>('api/causes/:docid|PUT');

export const nestApiDisableCause = FBF_CloudCallable<DocIdParamDto, any>(
  'api/causes/:docid/disable|DELETE'
);

export const nestApiEnableCause = FBF_CloudCallable<DocIdParamDto, any>(
  'api/causes/:docid/enable|PATCH'
);

