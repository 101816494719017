import {
  CardWrapperWithHeader,
  formatUnixTime,
} from '@rabbit/elements/shared-components';
import { Table } from '@rabbit/elements/shared-components';
import {
  capitalizeFirstLetter,
  getExpiryDate,
  getProductTitleFromHoldingData,
} from '../../../../utils/helpers';
import { useMemo } from 'react';
import placeholderImg from '../../../../assets/images/placeholder-image.png';
import { DTHoldingProxy, SELF_REGISTERED_VENDABLE } from '@rabbit/data/types';
import { BL_Warranty } from '@rabbit/bizproc/core';

interface RegistrationsOtherProductsSectionProps {
  allHoldingsList: DTHoldingProxy[] | null;
}
export default function RegistrationsOtherProductsSection({
  allHoldingsList,
}: RegistrationsOtherProductsSectionProps) {
  const results = allHoldingsList?.map((item: DTHoldingProxy) => {
    if (item?.vendable === SELF_REGISTERED_VENDABLE) return null;
    const warranty = BL_Warranty.getLatestWarranty(item.warranties);
    return {
      productImage: (
        <div className="pl-4">
          <img
            src={item?.img?.[0] ?? placeholderImg}
            alt={getProductTitleFromHoldingData(item)}
            className="w-16.5 h-16"
          ></img>
        </div>
      ),
      warrantyId: item?.docid.slice(0, 5).toUpperCase(),
      productName: getProductTitleFromHoldingData(item),
      modelNumber: item?.mpn && item.mpn !== 'n/a' ? item.mpn : '-',
      category: item?.category
        ? item.category?.map((i) => capitalizeFirstLetter(i)).join(', ')
        : '-',
      purchaseDate:
        item?.purchase_time && item?.purchase_time > 0
          ? formatUnixTime(item?.purchase_time, 'dd/MM/yyyy')
          : '-',
      warrantyExpiry:
        item?.purchase_time && warranty
          ? (getExpiryDate('string', item.docid, allHoldingsList) as string)
          : '-',
      warrantyExpired:
        item?.docid &&
        ((getExpiryDate('number', item?.docid, allHoldingsList) as number) *
          1000 <
        new Date().getTime()
          ? 'Yes'
          : 'No'),
    };
  });

  // Todo: make shape
  const resultsFiltered: any = results?.filter((item) => item !== null) ?? [];

  /* ------------------------------ Table columns ----------------------------- */
  const columns = useMemo(
    () => [
      {
        header: 'product image',
        accessorKey: 'productImage',
        size: 20,
      },
      {
        header: 'warranty id',
        accessorKey: 'warrantyId',
        size: 20,
      },
      {
        header: 'product name',
        accessorKey: 'productName',
        size: 15,
      },
      {
        header: 'model number',
        accessorKey: 'modelNumber',
        size: 15,
      },
      {
        header: 'category',
        accessorKey: 'category',
        size: 35,
      },
      {
        header: 'purchase date',
        accessorKey: 'purchaseDate',
        size: 10,
      },
      {
        header: 'warranty expiry',
        accessorKey: 'warrantyExpiry',
        size: 10,
      },
      {
        header: 'warranty expired',
        accessorKey: 'warrantyExpired',
        size: 10,
      },
    ],
    []
  );

  return (
    <CardWrapperWithHeader title={'Other products'} noPadding={true}>
      <Table
        columns={columns}
        data={resultsFiltered}
        inner={true}
        initialState={{
          showGlobalFilter: true,
        }}
        muiSearchTextFieldProps={{
          sx: {
            display: 'none',
          },
        }}
        muiTopToolbarProps={{
          sx: {
            display: 'none',
          },
        }}
        state={{
          isLoading: !allHoldingsList,
        }}
      />
    </CardWrapperWithHeader>
  );
}
