import { TrashIcon } from '@heroicons/react/24/solid';
import { FAULT_TYPE, useManageCauses } from '@rabbit/bizproc/react';
import { Button, Input } from '@rabbit/elements/shared-components';
import { Formik } from 'formik';
import { Dispatch, SetStateAction, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { DTCause } from '@rabbit/data/types';
import { useApi } from '@rabbit/utils/api';

export interface ModalAddEditFaultProps {
  handleClose: () => void;
  setLoading: Dispatch<SetStateAction<boolean>>;
  itemDetails?: DTCause;
  mode: 'edit' | 'new';
}

interface FormDataShape {
  cause: string;
  description?: string;
  enabled?: boolean; //by default its true in nest BE code
}

export function ModalAddEditCause({
  handleClose,
  itemDetails,
  mode,
  setLoading,
}: ModalAddEditFaultProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { createCause, editCause, disableCause } = useManageCauses(
    true,
    itemDetails?.docid
  );

  const { t } = useTranslation();

  const { createDoc: create, updateDoc: update, deleteDoc } = useApi();
  //
  const submit = async (values: FormDataShape) => {
    setIsSubmitting(true);
    setLoading(true);
    try {
      if (mode === 'edit' && itemDetails) {
        await update('Cause', {
          docid: itemDetails.docid,
          title: values.cause ?? '',
          description: values.description ?? '',
        });
      }
      if (mode === 'new') {
        await create('Cause', {
          title: 'test',
          description: 'test',
          enabled: false,
        });
      }
      toast.success(t('message.causeSavedSuccessfully'));
    } catch (error) {
      console.log(error);
      toast.error(t('message.errorSavingCause'));
    } finally {
      setIsSubmitting(false);
      setLoading(false);
      handleClose();
    }
  };

  const deleteCause = async () => {
    setIsSubmitting(true);
    setLoading(true);
    try {
      await deleteDoc('Cause', itemDetails?.docid || '');
      toast.success(t('message.causeDeletedSuccessfully'));
    } catch (error) {
      console.log(error);
      toast.error(t('message.errorDeletingCause'));
    } finally {
      setIsSubmitting(false);
      setLoading(false);
      handleClose();
    }
  };

  const schema = Yup.object({
    cause: Yup.string().trim().required(t('message.causeNameCantBeEmpty')),
    description: Yup.string().trim(),
  });

  const initialValues: FormDataShape = {
    cause: itemDetails ? itemDetails.title : '',
    description: itemDetails ? itemDetails.description : '',
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(props) => (
          <form>
            <div className="mt-4 flex gap-4 px-5 pb-2">
              <div className="flex-1">
                <Input
                  type="text"
                  label={`${t('general.cause')}*`}
                  name="cause"
                  settings={{
                    placeholder: t('general.causeName'),
                    hint: '*' + t('general.required'),
                  }}
                />
              </div>
            </div>

            <div className="py-2 px-5">
              <Input
                type="textarea"
                label={t('general.description')}
                name="description"
                settings={{
                  placeholder: `${t('message.writeTextHere')}...`,
                }}
              />
            </div>
            <div className="flex gap-5 py-4 px-5">
              <Button
                kind="primary"
                size="md"
                children={
                  mode === 'edit' ? t('general.saveAll') : t('general.save')
                }
                disabled={isSubmitting}
                onClick={props.handleSubmit}
              />
              {mode === 'edit' && (
                <Button
                  kind="red"
                  size="md"
                  icon={<TrashIcon />}
                  children={t('general.deleteCause')}
                  disabled={isSubmitting}
                  onClick={deleteCause}
                />
              )}
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}
