import { Warranty } from '@rabbit/data/types';
import { differenceInCalendarDays, differenceInCalendarMonths } from 'date-fns';

/**
 * Gets the first name, technically gets all words leading up to the last
 * Example: "Blake Robertson" --> "Blake"
 * Example: "Blake Andrew Robertson" --> "Blake Andrew"
 * Example: "Blake" --> "Blake"
 * @param str
 * @returns {*}
 */

export const getFirstName = (str: string) => {
  const arr = str.split(' ');
  if (arr.length === 1) {
    return arr[0];
  }
  return arr.slice(0, -1).join(' '); // returns "Paul Steve"
};

/**
 * Gets the last name (e.g. the last word in the supplied string)
 * Example: "Blake Robertson" --> "Robertson"
 * Example: "Blake Andrew Robertson" --> "Robertson"
 * Example: "Blake" --> "<None>"
 * @param str
 * @returns {string}
 */

export const getLastName = (str: string) => {
  const arr = str.split(' ');
  if (arr.length === 1) {
    return '';
  }
  return arr.slice(-1).join(' ');
};

export const getGreeting = () => {
  const currentTime = new Date();
  currentTime.getHours();
  const currentHour = currentTime.getHours();
  switch (true) {
    case currentHour < 12:
      return 'morning';
    case currentHour >= 12 && currentHour < 17:
      return 'afternoon';
    default:
      return 'evening';
  }
};

export const subtractYears = (date: Date, years: number) => {
  date.setFullYear(date.getFullYear() - years);
  return date;
};

export const calcWarrantyTimeLeft = (
  expirationDateInUnixTime?: number,
  fromDate?: number
) => {
  if (!expirationDateInUnixTime) return undefined;
  if (expirationDateInUnixTime === -1)
    return { unit: 'Lifetime Warranty', amount: null };
  if (expirationDateInUnixTime === 0) return { unit: 'n/a', amount: null };

  // Checks of a correct duration
  // 1. Every overflow is rounded up i.e. 1 month & 1 day = 2months
  // 2. It's 2 months remaining until the last 31 days

  // Today
  const from = fromDate ? new Date(fromDate) : new Date();
  const to = new Date(expirationDateInUnixTime);
  // TODO: I think an additional day is being added to this somewhere, but I'm REALLY in a hurry. Review later - DC
  // const days = differenceInCalendarDays(to, from);
  const days = differenceInCalendarDays(to, from) - 1;

  if (days < -1) return { amount: days, unit: 'expired' };
  if (days === -1) return { amount: -2, unit: 'expired' };
  if (days >= 31) {
    const totalMonths = differenceInCalendarMonths(to, from);
    return { amount: Math.floor(totalMonths), unit: 'months' };
  } else {
    return { amount: Math.floor(days), unit: 'days' };
  }
};

export const calcWarrantyRemaining = (
  warranty: Warranty | undefined
) => {

  if(!warranty) return undefined;

  const currentDate = Date.now();
  const {endDate: expirationDateInUnixTime, startDate, duration} = warranty;

  if (!expirationDateInUnixTime) return undefined;

  if (expirationDateInUnixTime === -1)
    return { unit: 'Lifetime Warranty', amount: null };

  if (expirationDateInUnixTime === 0) return { unit: 'n/a', amount: null };
  
  if(startDate && startDate>currentDate){
    return {amount: duration.amount, unit: duration.division}
  }
  // Determine the maximum date between startDate and currentDate
  const maxDate = Math.max(startDate || 0, currentDate);
  const from = new Date(maxDate);
  const to = new Date(expirationDateInUnixTime);

  // Calculate the remaining time
  const days = differenceInCalendarDays(to, from) - 1;
  if (days < -1) return { amount: days, unit: 'expired' };
  if (days === -1) return { amount: -2, unit: 'expired' };
  if (days >= 31) {
    const totalMonths = differenceInCalendarMonths(to, from);
    return { amount: Math.floor(totalMonths), unit: 'months' };
  } else {
    return { amount: Math.floor(days), unit: 'days' };
  }
};
